import { ClockIcon, ShareIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import Countdown from 'react-countdown'
import { useEffect, useState, createRef, RefObject } from 'react'

import {
  DATE_LOCALE,
  ENABLE_ARCHIVED_GAMES,
  ENABLE_MIGRATE_STATS,
} from '../../constants/settings'
import {
  ARCHIVE_GAMEDATE_TEXT,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
  STATISTICS_TITLE,
} from '../../constants/strings'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { solutionGameDate, tomorrow } from '../../lib/words'
import { Histogram } from '../stats/Histogram'
import { MigrationIntro } from '../stats/MigrationIntro'
import { StatBar } from '../stats/StatBar'
import { BaseModal } from './BaseModal'

import imageA from '../../images/1.jpg'
import imageB from '../../images/2.jpg'
import imageC from '../../images/3.jpg'
import imageD from '../../images/4.jpg'
import imageE from '../../images/5.jpg'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isLatestGame: boolean
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  handleMigrateStatsButton: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const StatsModal = ({
  isOpen,
  handleClose,
  gameStats,
  isLatestGame,
  isGameLost,
  isGameWon,
  handleMigrateStatsButton,
  numberOfGuessesMade,
}: Props) => {
  const [slideNumber, setSlideNumber] = useState(0)
  const [intervalCheck, setIntervalCheck] = useState(false)


  useEffect(() => {
    
    console.log("current " + slideNumber);
    console.log('wtf');

    setTimeout(() => {
      if(slideNumber === 4){
        console.log("reset");
        setSlideNumber(0);
      }  else {
        console.log("next");
        setSlideNumber(slideNumber + 1);
      }
    }, 3000);
  }, [slideNumber])
  
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
        {ENABLE_MIGRATE_STATS && (
          <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
        )}
      </BaseModal>
    )
  }

  const showImage = () => {
    switch(slideNumber) {
      case 0:
        return <img src={imageA} alt={"bad boy"} />
      case 1:
        return <img src={imageB} alt={"bad boy"} />
      case 2:
        return <img src={imageC} alt={"bad boy"} />
      case 3:
        return <img src={imageD} alt={"bad boy"} />
      default:
        return <img src={imageE} alt={"bad boy"} />
    }
  }

  return (
    <BaseModal
      title={"T-BONE 🥵"}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {(isGameLost || isGameWon) && (
        <div>
          <div className="inline-block w-full" style={{height: 500}}>
            {showImage()}
          </div>
          <div className="mt-5 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
            <div className="inline-block w-full text-left">
              {(!ENABLE_ARCHIVED_GAMES || isLatestGame) && (
                <div>
                  <h5>"Play" again?</h5>
                </div>
              )}
              {ENABLE_ARCHIVED_GAMES && !isLatestGame && (
                <div className="mt-2 inline-flex">
                  <ClockIcon className="mr-1 mt-2 mt-1 h-5 w-5 stroke-black dark:stroke-white" />
                  <div className="mt-1 ml-1 text-center text-sm sm:text-base">
                    <strong>{ARCHIVE_GAMEDATE_TEXT}:</strong>
                    <br />
                    {format(solutionGameDate, 'd MMMM yyyy', {
                      locale: DATE_LOCALE,
                    })}
                  </div>
                </div>
              )}
            </div>
            <div>
              <button
                type="button"
                className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                <ClockIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  )
}
