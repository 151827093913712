import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="TIME TO EXPOSE THE DOUCHEBAG" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        In honor of a special dude with attitude: WORDLE
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="correct"
        />
        <Cell value="H" isCompleted={true} />
        <Cell value="I" isCompleted={true} />
        <Cell value="S" isCompleted={true} />
      </div>
      <p className="text-xl text-gray-500 dark:text-gray-300">
        👏
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="I" isCompleted={true} isRevealing={true} status={'present'} />
        <Cell value="S" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        👏
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="N" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="P" status="absent" />
        <Cell value="O" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Nepo is short for nepotism
      </p>
    </BaseModal>
  )
}
